import crminfo from './crminfo.production.js';

const apiAuthUrl = 'https://oauth.dsh-agency.com';
const apiAuthBackUrl = 'https://auth-back.dsh-agency.com';
const apiSentry = 'https://dead0a4f63b646f0bdb16745fd896eca@o255621.ingest.sentry.io/6697831';
const apiSmsTool ='https://connect.dsh-agency.com';
const isProductionMode = true

const urls = {
    login: apiAuthUrl,
    logout: apiAuthUrl + '/logout',
    me: apiAuthBackUrl + '/self',
    replyTemplates: apiSmsTool + '/reply_templates?template_type=thread',
    apiSentry,
};

const PAGE_SIZE = 25;

export {
    urls,
    PAGE_SIZE,
    crminfo,
    isProductionMode,
};
